<template>
  <h3>NotificationsOptions</h3>
</template>

<script>

export default {
  name: "NotificationsOptions",
};
</script>
